<template>
    <div
        class="donut"
        :class="{
            'quarter-size': size === 'quarter',
            'half-size': size === 'half'
        }">
        <div
            class="donut-wrapper"
            :class="`height-${labels.length}`">
            <apex-chart
                ref="donut"
                height="100%"
                :options="options"
                :series="series" />
        </div>
        <div class="legend">
            <div
                v-for="(label, i) in labels"
                :key="`label-${i+1}`"
                class="label-wrapper"
                :class="`label-${i+1}`">
                <span class="label">{{ label.percentage }}% ({{ label.value }}) - {{ label.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import ApexChart from 'vue-apexcharts';
import getNumberFormats from '@/helpers/numberFormats';
import numeral from 'numeral';

export default {
    components: {
        ApexChart
    },
    props: {
        meta: {
            type: Object,
            required: true
        },
        metrics: {
            type: [Object, Array],
            required: true
        },
        size: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            numberFormats: getNumberFormats(),
            options: {
                chart: {
                    type: 'donut',
                    toolbar: { show: false }
                },
                legend: { show: false },
                colors: ['#7EDDFE', '#007FB8', '#E350B4', '#DE706A', '#F1BC57', '#856ADE', '#D7DE6A', '#6ADEBB', '#03A2EA', '#999999'],
                stroke: {
                    width: 0,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '70%'
                        }
                    }
                },
                yaxis: [
                    {
                        labels: {
                            formatter: (val) => { return this.formatValue(val) }
                        }
                    }
                ]
            }
        };
    },
    computed: {
        textSize() {
            return (this.size === 'quarter' || this.size === 'half') ? '9px' : '20px';
        },
        dataPoints() {
            return this.meta?.dataPoints ?? 3;
        },
        metricsAvailable() {
            return this.metrics?.length ?? false;
        },
        sortedMetrics() {
            const metrics = this.metrics.filter(m => {
                if (m.metrics[0].value !== null && m.metrics[0].value !== 0) { return m }
            });
            return metrics.sort(function(a, b) {
                if (a.metrics[0].value < b.metrics[0].value) { return 1 }
                if (a.metrics[0].value > b.metrics[0].value) { return -1 }
                return 0;
            });
        },
        series() {
            if (this.metricsAvailable) {
                return this.sortedMetrics.map(metric => {
                    const metricValue = metric.metrics[0].value === null ? 0 : metric.metrics[0].value;
                    return metricValue;
                }).slice(0, this.dataPoints);
            }
            return [];
        },
        labels() {
            if (this.metricsAvailable) {
                const currentMetrics = this.sortedMetrics.slice(0, this.dataPoints);
                return currentMetrics.map(metric => {
                    const total = this.series.reduce((a, b) => a + b, 0);
                    let percentage = 0;
                    if (total !== 0) { percentage = (100 * metric.metrics[0].value) / total }
                    return {
                        name: metric.campaign_name.value,
                        value: this.formatValue(metric.metrics[0].value),
                        percentage: Math.round(percentage * 10) / 10
                    };
                });
            }
            return [];
        }
    },
    watch: {
        metrics: {
            handler() {
                this.updateChartOptions();
            },
            deep: true
        }
    },
    mounted() {
        this.updateChartOptions();
    },
    methods: {
        formatValue(val) {
            if (this.metrics[0].metrics[0].format === 'currency' ) {
                return numeral(val).format(this.numberFormats.currency);
            }
            if (this.metrics[0].metrics[0].format === 'percent' ) {
                return numeral(val).format(this.numberFormats.percent);
            }
            if (this.metrics[0].metrics[0].format === 'decimal' ) {
                return numeral(val).format(this.numberFormats.decimal);
            }
            return val;
        },
        updateChartOptions() {
            this.$refs.donut.updateOptions({
                labels: this.labels.map(label => label.name),
                dataLabels: {
                    style: {
                        fontSize: this.textSize
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.donut {
    display: flex;
    &.half-size {
        width: 100%;
        justify-content: center;
        .donut-wrapper {
            height: 250px;
            width: 250px;
        }
        .legend {
            width: auto;
            margin-left: 30px;
        }
    }
    &.quarter-size {
        flex-direction: column;
        .donut-wrapper {
            width: 100%;
            &.height-10,
            &.height-9 {
                height: 190px;
            }
            &.height-8,
            &.height-7 {
                height: 205px;
            }
            &.height-6,
            &.height-5 {
                height: 220px;
            }
            &.height-4,
            &.height-3 {
                height: 235px;
            }
            &.height-2,
            &.height-1,
            &.height-0 {
                height: 250px;
            }
        }
        .legend {
            width: 100%;
            flex-direction: row;
            flex-flow: row wrap;
            justify-content: space-between;
            .label-wrapper {
                width: 43%;
                margin-left: 5%;
            }
        }
    }
}
.donut-wrapper {
    position: relative;
    height: 600px;
    width: 75%;
}
.legend {
    width: 25%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .label-wrapper {
        width: 100%;
        margin-bottom: 5px;
        position: relative;
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #7EDDFE;
            top: 3px;
            left: -15px;
        }
    }
    .label-2::after {
        background: #007FB8;
    }
    .label-3::after {
        background: #E350B4;
    }
    .label-4::after {
        background: #DE706A;
    }
    .label-5::after {
        background: #F1BC57;
    }
    .label-6::after {
        background: #856ADE;
    }
    .label-7::after {
        background: #D7DE6A;
    }
    .label-8::after {
        background: #6ADEBB;
    }
    .label-9::after {
        background: #03A2EA;
    }
    .label-10::after {
        background: #999999;
    }
    .label {
        display: block;
        font-size: 11px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>